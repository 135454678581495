import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('d52e0aa5-e00a-4c7d-9f4e-0b652d3bae09') as HTMLElement);

root.render(
	<React.StrictMode>
		<BrowserRouter><App /></BrowserRouter>
	</React.StrictMode>
);
