import React from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import {
	Route, Routes, Link
} from 'react-router-dom';

function Home() {
	return(
		<Typography paragraph>
			Keeta Infrastructure Operations dedicated to the management and monitoring of Keeta infrastructure.
		</Typography>
	);
}

function SecurityCommitments() {
	return(<Typography paragraph>Keeta is committed to the security of cardholder data.</Typography>);
}

function SecurityAnnouncements() {
	return(<Typography paragraph>In the event of a security incident, public disclosure will be here.</Typography>);
}

function ReportIncident() {
	return(<a href="https://forms.fillout.com/t/iT7NWXcDvQus">Click Here to report an Incident</a>);
}

interface PageDescriptor {
	title: string;
	href: string;
	component: React.ComponentType;
	alias?: string[];
	show?: boolean;
}

function App() {
	const drawerWidth = '12rem';

	const pages: PageDescriptor[] = [
		{ title: 'Home', href: '/', component: Home },
		{ title: 'Security Commitments', href: '/security/commitments', component: SecurityCommitments, alias: ['/security-commitments'], show: true },
		{ title: 'Security Announcements', href: '/security/announcements', component: SecurityAnnouncements, show: true },
		{ title: 'Report an Incident', href: '/report-incident', component: ReportIncident }
	];

	return(
		<>
   			<CssBaseline />
			<AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth})`, ml: `${drawerWidth}` }}>
				<Toolbar>
					 <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Keeta: Infrastructure Operations</Typography>
				</Toolbar>
			</AppBar>
			<Drawer open={true} variant="permanent" sx={{ width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' }}}>
				<List>
					{pages.map(function(page) {
						if (page.show === undefined) {
							if (page.href.slice(1).includes('/')) {
								return(undefined);
							}
						} else if (page.show === false) {
							return(undefined);
						}

						return(<ListItem key={page.title}><Link to={page.href}>{page.title}</Link></ListItem>);
					}).filter(function(page): page is JSX.Element {
						return(page !== undefined);
					})}
				</List>
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth})`, ml: `${drawerWidth}` }}>
				<Toolbar />
				<Routes>
					{pages.map(function(page, index) {
						const retval: JSX.Element[] = [];
						if (page.alias !== undefined) {
							let counter = -1;
							for (const alias of page.alias) {
								counter++;
								retval.push(<Route key={`${index}_${counter}`} path={alias} element={<page.component />} />);
							}
						}

						retval.push(<Route key={index} path={page.href} element={<page.component />} />);

						return(retval);
					})}
				</Routes>
			</Box>
		</>
	);
}

export default App;
